import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import GlobalStyle from "../styles/global"
import { ThemeProvider } from "styled-components"

import theme from "../styles/theme.json"

const Main = styled.main`
  overflow-x: hidden;
  width: 100%;
`

const Wrapper = ({ children }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Main>{children}</Main>
      </ThemeProvider>
    </>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
