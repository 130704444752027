import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"

const GlobalStyle = createGlobalStyle`
  /* Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,700;1,400;1,700&family=Sedgwick+Ave&display=swap');



  /* Normalize.css */
  ${normalize}

  /* GENERAL */
  * {
    -webkit-font-smoothing: antialiased;
  }

  *::selection {
    background-color: ${({ theme }) => theme.colors.highlight};
  }

  html {
    background-color: ${({ theme }) => theme.colors.bg};
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.fg};
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  html, body {
    overscroll-behavior: none;
  }
  
  *, 
  *:before,
  *:after {
    box-sizing: inherit;
  }

  /* UTILS */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  /* TYPOGRAPHY */
  html {
    font: 16px/1.5 'Poppins', sans-serif;
  }

  /* ACCESSIBILITY */
  *:focus-visible {
    outline-color: ${({ theme }) => theme.colors.favor};
    outline-style: dotted;
    outline-width: 3px;
  }

  *::selection {
    background: var(--highlight, #fff);
    color: ${({ theme }) => theme.colors.bg};
  }

  /* MAIN STYLES */
  main {
    /* Typography */
    h1 {
      font-weight: 100;
      text-transform: uppercase;
      font-size: 3rem;
      line-height: 0.85;
      margin: 1.5em 0 1em;
    }

    h2 {
      font-size: 2.3rem;
      line-height: 1.2;
      margin: 1.5em 0 0.5em;
    }

    h3 {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.2;
      margin: 1.5em 0;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:focus,
      &:hover {
        background-color: ${({ theme }) => theme.colors.highlight};
      }
    }

    /* Media */
    img {
      height: auto;
      max-width: 100%;
    }

    @media (min-width: 650px) {
      h2 {
        font-size: 3.5rem;
      }

      p, span, ul {
        font-size: 1.33rem;
      }
    }

  }
`

export default GlobalStyle
